<template>
  <div class="w-q">
    <div class="left">
      <worker-card :only-head="true" class="worker-card-item" :data="queueWorker.worker">
      </worker-card>
    </div>
    <div class="sort-num">{{queueWorker.sortNum}}</div>
    <div class="right">
      <div class="info-item">
        <span>员工姓名：</span>
        <span style="margin-right: 30px">{{queueWorker.worker.name}}</span>
        <span>所属职务：</span>
        <span>{{queueWorker.worker.positionData ? queueWorker.worker.positionData.name : '-'}}</span>
      </div>
      <div class="info-item" v-if="queueWorker.workerCertificate">
        <span>适岗专业技术资格证书：</span>
        <span style="margin-right: 30px">{{queueWorker.workerCertificate.certificateTitle}}</span>
        <span>适岗专业技术资格证书获得时间：</span>
        <span>{{queueWorker.workerCertificate.getDate ? queueWorker.workerCertificate.getDate.slice(0, 10) : '-'}}</span>
      </div>
      <div class="info-item">
        <span>当前岗位：</span>
        <span style="margin-right: 30px">{{queueWorker.workerJobTitle ? (queueWorker.workerJobTitle.jobTitleType + queueWorker.workerJobTitle.jobTitleTitle + '级') : '-'}}</span>
        <span>当前岗位聘任起始时间：</span>
        <span style="margin-right: 30px">{{queueWorker.workerJobTitle && queueWorker.workerJobTitle.hireStartDate ? queueWorker.workerJobTitle.hireStartDate.slice(0, 10) : '-'}}</span>
        <span v-if="queueWorker.jobTitle.needMLevel && queueWorker.jobTitle.needYear">{{queueWorker.jobTitle.needMLevel}}累积工作年限：</span>
        <span v-if="queueWorker.jobTitle.needMLevel && queueWorker.jobTitle.needYear">{{queueWorker.countYear !== null ? queueWorker.countYear : '-'}}</span>
      </div>
      <div class="info-item">
        <span>参加工作时间：</span>
        <span style="margin-right: 30px">{{queueWorker.worker.correctionDate ? queueWorker.worker.correctionDate.slice(0, 10) : '-'}}</span>
        <span>入职单位时间：</span>
        <span>{{queueWorker.worker.entryDate ? queueWorker.worker.entryDate.slice(0, 10) : '-'}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import WorkerCard from './workerCard'

export default {
  components: {
    WorkerCard
  },
  props: {
    queueWorker: Object
  }
}
</script>

<style lang="less" scoped>
.w-q {
  display: flex;
  position: relative;
  width: 100%;
  .sort-num {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    width: 30px;
    height: 30px;
    background: #F4628F;
    border-radius: 100%;
  }
  .right {
    flex: 1;
    background: #FAFAFA;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    .info-item {
      line-height: 35px;
    }
  }
}
</style>
