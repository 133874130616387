<template>
  <sign-page
    title-text="职称聘任排队情况"
    :request="request"
    :column-list="columnList"
    table-size="large"
    :tableActionsFixed="true"
    :titleMenus="[]"
    @tableAction="tableAction"
    :tableActions="tableActions"
    :need-data-file="true">
    <fm-modal v-model="modal" width="80%" :title="(chooseData ? (chooseData.type + chooseData.title) : '') + '排队明细'">
      <div style="min-height:20rem" v-if="modal">
        <div class="worker-queue-list" v-if="showData && showData.length > 0">
          <worker-queue :queueWorker="item" style="margin-bottom:20px;" v-for="(item, index) in showData" :key="index">
          </worker-queue>
        </div>
        <div class="worker-card-list-null" v-else>
          <img src="/static/images/null.png" style="width: 40px;margin-bottom:20px;"/>
          <div>暂无数据</div>
        </div>
      </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  jobTitleRequest as request
} from '../../api'

import WorkerQueue from './worker/workerQueue'

export default {
  components: {
    WorkerQueue
  },
  methods: {
    async loadData () {
      let datas = await request.getQueue()
      datas.sort((a, b) => {
        return (a.lelel || 0) - (b.lelel || 0)
      })
      datas.forEach(v => {
        v.queue.sort((a, b) => a.sortNum - b.sortNum)
        v.queueLength = v.queue.length
        v.noteText = v.lastNum > 0 && v.queueLength > 0 ? '请尽快安排岗位调整' : ''
      })
      return datas
    },
    tableAction (parm) {
      this.chooseData = parm.data
      this.modal = true
    }
  },
  computed: {
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    tableActions () {
      return [{
        'key': 'queue',
        label: '排队明细'
      }]
    },
    showData () {
      return this.chooseData ? this.chooseData.queue : []
    },
    columnList: {
      get () {
        let data = [{
          title: '岗位类型',
          sort: true,
          field: 'type',
          filters: this.$store.getters.selectDatas['job_title_type'].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '岗位层级',
          sort: true,
          field: 'topLevel',
          filters: [{key: '初级', label: '初级'}, {key: '中级', label: '中级'}, {key: '高级', label: '高级'}].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '职称级别',
          sort: true,
          field: 'mLevel',
          filters: [{key: '员级', label: '员级'}, {key: '助理级', label: '助理级'}, {key: '中级', label: '中级'}, {key: '副高级', label: '副高级'}, {key: '正高级', label: '正高级'}].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '岗位等级',
          sort: true,
          field: 'title',
          filterMethod (parm) {
            return parm.row.title === parm.value
          }
        },
        // {
        //   title: '需要资格证书',
        //   field: 'needCertificateTitle'
        // },
        // {
        //   title: '需要职称',
        //   field: 'needJobTitleTitle'
        // },
        // {
        //   title: '需要职称年限',
        //   field: 'needYear'
        // },
        {
          title: '实际可用岗位数',
          sort: true,
          filterRange: true,
          field: 'num',
          dataType: Number
        },
        {
          title: '岗位实际人数',
          filterRange: true,
          sort: true,
          field: 'workerJobTitleNum',
          dataType: Number
        },
        {
          title: '岗位空置数',
          sort: true,
          filterRange: true,
          field: 'lastNum',
          dataType: Number,
          render: (h, rowData) => {
            return h('div', isNaN(rowData.lastNum) ? '-' : (rowData.lastNum < 0 ? 0 : rowData.lastNum))
          }
        },
        {
          title: '排队人数',
          filterRange: true,
          sort: true,
          field: 'queueLength',
          dataType: Number
        },
        {
          field: 'noteText',
          title: '提醒'
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: {
        get: this.loadData
      },
      modal: false,
      chooseData: null
    }
  }
}
</script>

<style lang="less" scoped>
.worker-card-list-null {
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>